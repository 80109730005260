import * as api from "../../shared/api-client"
import {
    AppError,
    AppUser,
    BreadcrumbItem,
    LOAD_APP,
    SET_BREADCRUMB_STACK,
    SET_GLOBAL_ERROR,
    SET_MENU_IS_EXPANDED,
    SET_PAGE_ERROR,
    SHOW_APP,
    TOGGLE_MENU
} from "./types";
import { initializeDatasourceFetchPipeline } from "../storyline/actions";
import { RootState } from "..";
import { openWsConnection } from "../vscode/actions";


export function loadApp(user: AppUser) {
    return async (dispatch, getState: () => RootState) => {
        dispatch({
            type: LOAD_APP,
            user,
        });

        initializeDatasourceFetchPipeline()(dispatch, getState);

        // Immediately try to open the VS Code WebSocket connection if the user is a developer...
        if (user.isDeveloper && window.location.hostname !== "documentation.dev.qerent.ai") {
            dispatch(openWsConnection());
        }

        const menuItems = await new api.MenuItemsClient().getAll().catch(ex => {
            const hostName = window.location.hostname;

            const getError = (ex): AppError => {
                if (ex.status == null)
                    return { type: "Connectivity", message: `Failed to contact Qerent Web Application.`, details: `Unable to contact the server at \`${hostName}\`.\n\nPlease contact your IT team for assistance to ensure network access to this address.` };

                return { type: "Generic", message: "Failed to load menu items.", details: ex.response };
            };

            dispatch(setPageError(getError(ex)));
            return [];
        });

        dispatch({
            type: SHOW_APP,
            menuItems
        });
    }
}

export function toggleMenu() {
    return {
        type: TOGGLE_MENU
    }
}

export function setMenuIsExpanded(value: boolean) {
    return {
        type: SET_MENU_IS_EXPANDED,
        value: value
    }
}

export function setBreadcrumbStack(breadcrumbStack: BreadcrumbItem[]) {
    return {
        type: SET_BREADCRUMB_STACK,
        breadcrumbStack: breadcrumbStack
    }
}

export function setGlobalError(error: AppError) {
    return {
        type: SET_GLOBAL_ERROR,
        error
    };
}

export function setPageError(error: AppError) {
    return {
        type: SET_PAGE_ERROR,
        error
    };
}