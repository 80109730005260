import React from "react";
import Storyline from "./Storyline/Storyline";
import Home from "./Home/Home";
import * as _ from "lodash";
import {
    Routes,
    Route,
    Navigate,
    useParams,
    Outlet,
} from "react-router-dom";
import { connect } from "react-redux";
import { loadStoryline, loadCanvas } from "../store/storyline/actions";
import { setMenuIsExpanded } from "../store/app/actions";
import { RootState } from "../store";
import { MenuItemDisplayModel } from "../shared/api-client";
import useQueryParams from "../shared/hooks/useQueryParams";
import clsx from "clsx";
import Sidenav from "../shared/components/Sidenav";
import SidenavMiniMap from "./SidenavMiniMap/SidenavMiniMap";
import NotFound from "../shared/pages/NotFound";

const activeItemContent = <SidenavMiniMap />;

interface ViewerProps {
    menuItems: MenuItemDisplayModel[],
    parameterValues: Map<string, any>,
    storylineId?: string,
    loadStoryline: typeof loadStoryline,
    loadCanvas: typeof loadCanvas;
    setMenuIsExpanded: typeof setMenuIsExpanded
}

function NavigateToHomeOrFirstDirectLink(props: { menuItems: MenuItemDisplayModel[] }) {
    const { menuItems } = props;
    const directLinks = _.chain(menuItems).flatMapDeep(mi => [mi, ...mi.children]).filter(mi => !mi?.children?.length).value();

    return directLinks.length !== 1 ?
        <Home /> :
        <Navigate to={directLinks[0].url} />
}

export function RenderStoryline(props: { loader: (string) => any }) {
    const { loader } = props;
    const { id } = useParams();

    React.useEffect(() => {
        loader(id);
    }, [id])

    return (
        <Storyline id={id} />
    )
}

export function useViewerState(parameterValues: Map<string, any>, loadStoryline, loadCanvas, setMenuIsExpanded: (value: boolean) => { type: string; value: boolean; }) {
    const visibilityClasses = [
        String(parameterValues.get("isExport")) === "1" ? "is-export" : undefined,
        String(parameterValues.get("hideTitleBar")) === "1" ? "hide-titlebar" : undefined,
        String(parameterValues.get("hideMinimap")) === "1" ? "hide-minimap" : undefined,
        String(parameterValues.get("hideCommentary")) === "1" ? "hide-commentary" : undefined
    ];

    const queryParams = useQueryParams();

    const ensureCorrectStorylineIsLoaded = (loader: typeof loadStoryline | typeof loadCanvas) => (id: string) => {
        // If nav-filters-id parameter is passed in, fetch the parameter values from session storage and include those in the full parameter set...
        const navFiltersId = queryParams.get("nav-filters-id");
        const navFiltersParams = navFiltersId ?
            JSON.parse(sessionStorage.getItem(`nav-filters-${navFiltersId}`) ?? "{}") :
            {};

        const allParameters = new Map<string, any>([
            ...Object.entries(navFiltersParams),
            ...queryParams.entries()
        ]);

        if (allParameters.has("menu-expanded")) {
            const expandedParamValue = allParameters.get("menu-expanded");
            setMenuIsExpanded(expandedParamValue === 1 || expandedParamValue === "true");
        }

        loader(id, allParameters);
    };
    return { visibilityClasses, ensureCorrectStorylineIsLoaded };
}

function Viewer(props: ViewerProps) {
    const { parameterValues, storylineId, menuItems, loadStoryline, loadCanvas, setMenuIsExpanded } = props;
    const { visibilityClasses, ensureCorrectStorylineIsLoaded } = useViewerState(parameterValues, loadStoryline, loadCanvas, setMenuIsExpanded);

    return (
        <Routes>
            <Route element={
                <div id={`storyline-${storylineId}`} className="app-layout storyline-page">
                    <Sidenav menuItems={menuItems} activeItemContent={activeItemContent} className={clsx(visibilityClasses)} />
                    <div className={clsx("content", visibilityClasses)}>
                        <Outlet />
                    </div>
                </div>
            }>
                <Route index element={<NavigateToHomeOrFirstDirectLink menuItems={menuItems} />} />
                <Route path="storylines/:id" element={<RenderStoryline loader={ensureCorrectStorylineIsLoaded(loadStoryline)} />} />
                <Route path="canvases/:id" element={<RenderStoryline loader={ensureCorrectStorylineIsLoaded(loadCanvas)} />} />
                <Route path="*" element={<NotFound />} />
            </Route>
        </Routes>
    );
}

export default connect(
    (state: RootState) => ({
        parameterValues: state.storyline.parameterValues,
        storylineId: state.storyline.id,
    }),
    { loadStoryline: loadStoryline as any, loadCanvas: loadCanvas as any, setMenuIsExpanded })(Viewer);
