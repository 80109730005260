import _ from "lodash";
import addRetryLogicToFetch from "fetch-retry";
import { attachToEnvironment as attachFetchInterceptorToEnvironment } from "./services/fetchInterceptService";

// Make lodash available globally...
window._ = _;

/// Use ISO 8601 format for all serialized dates...
Date.prototype.toString = Date.prototype.toISOString; // eslint-disable-line no-extend-native


/// Monkey-patch fetch() to retry failed requests and allow request interception...
const { fetch: originalFetch } = window;
const fetchWithRetry = addRetryLogicToFetch(originalFetch);
const defaultRetryOptions = {
    retries: 3,
    // Exponential backoff...
    retryDelay: function (attempt, _error, _response) {
        return Math.pow(2, attempt) * 1000; // 1000, 2000, 4000
    },
    retryOn: [
        408, // Request Timeout
        429, // Too Many Requests
        500, // Internal Server Error
        502, // Bad Gateway
        503, // Service Unavailable
        504, // Gateway Timeout
    ]
};
window.fetch = (url, options) => fetchWithRetry(url, { ...defaultRetryOptions, ...options }); // Use default retry options, with any overrides from the caller...
const fetchIntercept = attachFetchInterceptorToEnvironment(window);
export { fetchIntercept };